import React, { useState, useEffect, useMemo } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Header from "../header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import FooterSection from "../footer/FooterSection";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";

import "../../App.css";
import "../subject/Subject.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";

import clock from "../../assets/images/clock.svg";
import calendar from "../../assets/images/calendar.svg";
import image162 from "../../assets/images//image162.png";
import attendance from "../../assets/images/attendance.svg";
import studyMaterial from "../../assets/images/studyMaterial.png";
import watchRecording from "../../assets/images/watchRecording.png";
import classNotes from "../../assets/images/classNotes.png";
import assignmentImg from "../../assets/images/assignment_img.png";
import download from "../../assets/images/gray_download.svg";
import got from "../../assets/images/got.jpg";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VideoModal from "../common-components/VideoModal";
import Advertisement from "../common-components/Advertisement";
import Schedule from "./ViewSchedules";
import useTranslations from "../../custom-hooks/useTranslations";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { encryptData } from "../../services/encryptDecrypt";
import generateAgoraToken from "../../services/AgoraTokenService";
import { ZoomImageContainer } from "../common-components/ZoomImageContainer";
import { getCourses, GetGroupClassSchedule, getPaymentLink } from "../../actions/coursesAction";
import { axiosPost } from "../../services/api";
import { API_BASE_URL } from "../../config/apiConfig";
import { getStudentProfile } from "../../actions/studentProfileAction";
import FeedbackModal from "../common-components/FeedbackModal";
import Grid from "@mui/material/Grid";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CoursesSchedules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classId, tab } = useParams();
  const decodedId = atob(classId);
  const translations = useTranslations();
  const [isScheduleModal, setIsScheduleModal] = useState(false);
  const [filterSessions, setFilterSessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState("0");
  const [selectedMonth, setSelectedMonth] = useState("0");
  const [value, setValue] = useState(tab ? Number(atob(tab)) : 0);
  const [isApiCalling, setIsApiCalling] = useState(true);
  const [selectedSubjects, setSelectedSubjects] = useState({});
  const [scheduleList, setScheduleList] = useState([]);
  console.log("scheduleList",scheduleList);
  



  const translation = {
    ...translations[8]?.PrivateClassDashboard,
    ...translations[12]?.ClassDetail,
    ...translations[13]?.Payment,
    ...translations[19]?.PaymentDetails,
    ...translations[21]?.CommonFrontend,
    ...translations[23]?.TutorRegistration,
    ...translations[26]?.QuickCall,
    ...translations[31]?.Wallet,
    ...translations[36]?.PracticeTest,
    ...translations[38]?.GroupCourses,
  };

  const privateTutorSessionID = sessionStorage.getItem("SessionID");
  const profileInfo = Cookies.get("ProfileInfo")
    ? JSON.parse(Cookies.get("ProfileInfo"))
    : null;
  const courses = useSelector(({ coursesReducer }) => coursesReducer.courses);  
  const groupClass = useSelector(
    ({ GetGroupClassScheduleReducer }) =>
      GetGroupClassScheduleReducer.groupClass
  );
  
  const selectedCourse = useMemo(() => {
    return courses?.AllCourses?.find((course) => course.ID === decodedId);
  }, [courses, decodedId]);
  console.log("selectedCourse",selectedCourse);
  


  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const formatSessions = (arr) => {
    const monthsMap = {};
    
    arr.forEach(({ SessionDate, TopicName, TopicColor }) => {
      const dateObj = new Date(SessionDate);
      const monthName = dateObj.toLocaleString("en-US", { month: "long" });
      const sessionDate = dateObj.toLocaleDateString("en-GB").split('/').reverse().join('-');
      const startTime = dateObj.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
      
      if (!monthsMap[monthName]) {
      monthsMap[monthName] = {};
      }
      
      if (!monthsMap[monthName][sessionDate]) {
      monthsMap[monthName][sessionDate] = [];
      }
      
      monthsMap[monthName][sessionDate].push({
      Name: TopicName,
      StartTime: startTime,
      Color: TopicColor,
      });
    });
    
    return Object.keys(monthsMap).map((month) => ({
      Name: month,
      Dates: Object.keys(monthsMap[month]).map((date) => ({
      SessionDate: date,
      Topic: monthsMap[month][date],
      })),
    }));
    };
  

  const handleModalOpen = (course) => {
    
    const topicIds = course?.Subject?.map((topic) => topic.TopicID);
    if (topicIds) {
      dispatch(GetGroupClassSchedule({ TopicIDs: topicIds, CourseID: decodedId })).then((response)=>{
        console.log("res>>>>>", response);
        
        const formattedData = formatSessions(response?.Data?.Sessions);
        setScheduleList(formattedData)
        
      })
    }  
    setIsScheduleModal(true)
  };

  const handleModalClose = () => {
    setIsScheduleModal(false);
  };


  const totalSessionHours = selectedCourse?.Subject?.reduce((sum, subject) => {
    return sum + parseInt(subject.SessionHours, 10);
  }, 0);

  const totalHoursInHours = (totalSessionHours / 60).toFixed(0);

  const totalSessionCount = selectedCourse?.Subject?.reduce((sum, subject) => {
    return sum + parseInt(subject.SessionCount, 10);
  }, 0);


  const handleToggle = (index) => {
    setSelectedSubjects((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Calculate totalDiscountPrice & totalActualPrice for selected subjects
  const totalDiscountPrice = selectedCourse?.Subject?.reduce(
    (total, subject, index) =>
      selectedSubjects[index] ? total + Number(subject?.DiscountPrice || 0) : total,
    0
  );

  const totalActualPrice = selectedCourse?.Subject?.reduce(
    (total, subject, index) =>
      selectedSubjects[index] ? total + Number(subject?.ActualPrice || 0) : total,
    0
  );
  
  const EnrollNow = (amount) => {
    const topicIds = selectedCourse?.Subject?.map((topic) => topic.TopicID);

    if (topicIds) {   
      const data = {
        CourseID: decodedId,
        TopicIDs: topicIds,
        TransactionAmount: amount,
        UserName: profileInfo?.UserName,
        Email:profileInfo?.Email,
        Phone:profileInfo?.Phone,
      }      

      dispatch(getPaymentLink(data)).then((response)=>{

        if(response.StatusCode === 200){
          // window.location.href = response.Data.PaymentURL;
        } 
          
      })
    }
  }
    

  useEffect(() => {
    if (selectedCourse?.Subject) {
      const initialState = {};
      selectedCourse.Subject.forEach((_, index) => {
        initialState[index] = true;
      });
      setSelectedSubjects(initialState);
    }
  }, [selectedCourse]);  


  useEffect(() => {
    dispatch(getCourses())
  }, []);



  
  return (
    <div>
      <Header />
      <Box className="quikCallBanner">
        <Box
          className="OuterContainer"
          sx={{ border: "none", borderRadius: "0px" }}
        >
          <Typography variant="h1">
            {translation?.Hi} {profileInfo?.UserName},{" "}
            <Typography variant="div"> {translation?.WelcomeYasa}</Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        className="OuterContainer containerShape"
        sx={{ background: "#EAF4FF", padding: "30px" }}
      >
        <Box className="SectionIn">
          <Box className="BackBtn">
            <Button
              className="backBtn"
              variant="text"
              sx={{ mb: 2 }}
              startIcon={
                localStorage.getItem("I18N_LANGUAGE") === "en" ? (
                  <ArrowBackIcon />
                ) : (
                  <ArrowForward />
                )
              }
              onClick={handleBack}
            >
              {translation?.BackBtn}
            </Button>
          </Box>
          <Box sx={{ width: "100%" }} >
          <Box className="courseBox">
              <img
                  src={selectedCourse?.CourseImage}
                  alt="Course Thumbnail"
                  className="thumbnailImage"
              />
                {/* Right-aligned icons inside the image */}
                <Box className="infoColumn infoColumnRight">
                <Box className="infoItem">
                    <AccessTimeIcon className="iconStyle" />
                    <Typography variant="body2">{totalHoursInHours} {translation?.gcHours}</Typography>
                </Box>
                <Box className="infoItem">
                    <OndemandVideoIcon className="iconStyle" />
                    <Typography variant="body2">{totalSessionCount} {translation?.gcSessions}</Typography>
                </Box>
                <Box className="infoItem">
                    <PeopleOutlineIcon className="iconStyle" />
                    <Typography variant="body2">{selectedCourse?.RegisteredStudentCount} {translation?.gcStudents}</Typography>
                </Box>
                <Box className="infoItem">
                    <StarPurple500Icon className="iconStyle" />
                    <Typography variant="body2">{selectedCourse?.CourseRate} ({selectedCourse?.CourseRateCount}) </Typography>
                </Box>
                </Box>
            </Box>
            </Box>
            <Box className="custom-panel-wrapper">
                <Box >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="coursesDetails"
                        sx={{
                            "& .MuiTabs-indicator": {
                                display: "unset",
                            },
                        }}
                    >
                        <Tab label={translation?.gcSubjects} className="coursesDetailsTab" sx={{display: 'unset'}} {...a11yProps(0)} />
                        <Tab label={translation?.gcOverview} className="coursesDetailsTab" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Box sx={{ width: "100%", p: 2, mx: "auto" }}>
                        <Typography variant="h6" gutterBottom>
                            {translation?.gcSelectSubject}
                        </Typography>
                        <List>
                            {selectedCourse?.Subject?.map((subject, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                }}
                            >
                                {/* Subject Name & Sessions */}
                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="body1" fontWeight="bold">
                                      {subject.TopicName}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                  <CalendarMonthIcon sx={{ color: "gray", mr: 1 }} />
                                  {subject?.SessionCount} Sessions
                                  </Typography>
                                </Box>

                                {/* Price Section - Centered Vertically */}
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mx: 2 }}>
                                <Typography
                                    variant="body2"
                                    sx={{ textDecoration: "line-through", color: "gray", fontWeight: 100 }}
                                >
                                    {subject?.ActualPrice}
                                </Typography>
                                <Typography variant="body2" sx={{ color: "#0F4D92", fontWeight: "bold" }}>
                                    {subject?.DiscountPrice}
                                </Typography>
                                </Box>

                                <Switch
                                  key={index}
                                  checked={!!selectedSubjects[index]}
                                  onChange={() => handleToggle(index)}
                                />

                                </ListItem>
                            ))}
                        </List>

                        {/* View Schedule Button */}
                        <Box sx={{textAlign:"center"}}>
                            <Button
                                variant="contained"
                                sx={{
                                mt: 2,
                                minWidth:'300px',
                                backgroundColor: "#0F4D92",
                                "&:hover": { backgroundColor: "#0D3B6C" },
                                }}
                                onClick={()=> handleModalOpen(selectedCourse)}
                            >
                                {translation?.gcViewSchedules}
                            </Button>
                        </Box>

                        {/* Includes Section */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" gutterBottom>
                            {translation?.gcIncludes}
                            </Typography>
                            <List>
                            <ListItem sx={{ display: "flex", alignItems: "center" }}>
                                <CalendarMonthIcon sx={{ color: "gray", mr: 1 }} />
                                <Typography variant="body2" color="textSecondary">
                                {translation?.gcIncludes_1}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "flex", alignItems: "center" }}>
                                <VideoLibraryIcon sx={{ color: "gray", mr: 1 }} />
                                <Typography variant="body2" color="textSecondary">
                                {translation?.gcIncludes_2}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ display: "flex", alignItems: "center" }}>
                                <DescriptionIcon sx={{ color: "gray", mr: 1 }} />
                                <Typography variant="body2" color="textSecondary">
                                {translation?.gcIncludes_3}
                                </Typography>
                            </ListItem>
                            </List>
                        </Box>
                        <Box
                            className="priceBox"
                            sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "16px",
                            }}
                        >
                            {/* <Typography
                                className="coursePrice"
                                variant="body2"
                                sx={{ fontWeight: 'medium', fontSize: '18px' }}
                            >
                                {course.Subject?.[0]?.DiscountPrice} {course.Subject?.[0]?.Currency}
                            </Typography> */}

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              { totalDiscountPrice ? 
                                <>
                                  <Typography variant="body2" sx={{ textDecoration: "line-through", color: "gray", mr: 1 }}>
                                  {totalActualPrice} {translation?.KuwaitiDinar}
                                  </Typography>
 
                                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
                                  {totalDiscountPrice} {translation?.KuwaitiDinar}
                                  </Typography> 
                                </>
                                : ""
                              }
                            </Box>
                            <Box>
                            <button
                                className="viewDetailsBtn"
                                onClick={() => EnrollNow(totalDiscountPrice)}
                            >
                                {translation?.gcEnrollNow} 
                            </button>
                            </Box>
                        </Box>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box sx={{ width: "100%", p: 2, mx: "auto" }}>
                    <Typography variant="h6" >
                        {translation?.gcDescription}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={1}>
                       {selectedCourse?.CourseDescription}
                    </Typography>
                    
                    <Box mt={2}>
                        <Typography variant="body1" fontWeight="medium">
                        <CalendarMonthIcon sx={{ color: "gray", mr: 1 }} /> {totalSessionCount} Sessions for {selectedCourse?.CourseMonths?.length} month course
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                        <VideoLibraryIcon sx={{ color: "gray", mr: 1 }} /> {translation?.gcDescription_2}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                        <DescriptionIcon sx={{ color: "gray", mr: 1 }} /> {translation?.gcDescription_3}
                        </Typography>
                    </Box>
                    
                    <Typography variant="h6"  mt={3}>
                        {translation?.gcMasterTutors}
                    </Typography>
                        <Grid container spacing={2} mt={1}>
                          {selectedCourse?.TutorList?.map((tutor, index) => (
                            <Grid item key={index} xs={6}  md={2} textAlign="center">
                              <Avatar src={tutor.TutorImage} alt={tutor.TutorName} sx={{ width: 56, height: 56, mx: "auto" }} />
                              <Typography variant="body2">{tutor.TutorName}</Typography>
                            </Grid>
                          ))}
                        </Grid>
                    
                    <Typography variant="h6"  mt={3}>
                        {translation?.gcAskQuestionFrequently}
                    </Typography>
                    <Accordion sx={{ mt: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Is Yasa a free app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>Yes, there is a premium user offer.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ mt: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>What is Yasa Premium?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>Yes, there is a premium user offer.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ mt: 1 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>How to buy a Yasa Premium subscription?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>Yes, there is a premium user offer.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    </Box>
                </CustomTabPanel>
            </Box>
          <Advertisement />
        </Box>
      </Box>

      {/* {View schedules modal} */}
      <Schedule   isOpen={isScheduleModal} handleClose={handleModalClose} data={scheduleList} />

      <FooterSection />
    </div>
  );
};
export default CoursesSchedules;
