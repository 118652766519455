import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
  Rating,
  Avatar,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Header from "../header/Header";
import FooterSection from "../footer/FooterSection";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../App.css";
import "../subject/Subject.css";
import "./courses.css";
import "../responsive/Responsive.css";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "../../custom-hooks/useTranslations";
import { getCourses } from "../../actions/coursesAction";

const Courses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translations = useTranslations();
  const [alignment, setAlignment] = useState('My Courses');
  const [isApiCalling, setIsApiCalling] = useState(true);  

  const translation = {
    ...translations[0]?.Login,
    ...translations[8]?.PrivateClassDashboard,
    ...translations[12]?.ClassDetail,
    ...translations[12]?.Payment,
    ...translations[18]?.YourCourses,
    ...translations[21]?.CommonFrontend,
    ...translations[26]?.QuickCall,
    ...translations[38]?.GroupCourses,
  };

  const profileInfo = Cookies.get("ProfileInfo")
    ? JSON.parse(Cookies.get("ProfileInfo"))
    : null;
  const courses = useSelector(({ coursesReducer }) => coursesReducer.courses);
  
  const totalSessionCount = courses?.MyCourses[0]?.Subject?.reduce((sum, subject) => {
    return sum + parseInt(subject.SessionCount, 10);
  }, 0);
  

  const totalSessionHours = courses?.MyCourses[0]?.Subject?.reduce((sum, subject) => {
    return sum + parseInt(subject.SessionHours, 10);
  }, 0);

const totalHoursInHours = (totalSessionHours / 60).toFixed(0);

  const filteredClasses = alignment === 'My Courses' ? courses?.MyCourses : courses?.AllCourses;

  const handleChange = (event, newAlignment) => {
      if (newAlignment !== null) setAlignment(newAlignment);
  };

  const viewSchedules = (id) => {
    navigate(`/courses-schedules/${btoa(id)}`);
  };

  const viewDetails = (id) => {
    navigate(`/courses-details/${btoa(id)}`);
  };

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  useEffect(() => {
    if (courses) {
      setIsApiCalling(false);
    }
  }, [courses]);  

  return (
    <div>
      <Header />
      <Box className="quikCallBanner">
        <Box
          className="OuterContainer"
          sx={{ border: "none", borderRadius: "0px" }}
        >
          <Typography variant="h1">
            {translation?.Hi} {profileInfo?.UserName},{" "}
            <Typography variant="div"> {translation?.WelcomeYasa}</Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        className="OuterContainer containerShape"
        sx={{ background: "#EAF4FF", padding: "30px" }}
      >
        <Box className="SectionIn">
          <Box className="headbox">
            <Typography variant="h4">{translation?.gcMyCourses}</Typography>
              <Box className="customToggle">
                          <ToggleButtonGroup
                              color="primary"
                              value={alignment}
                              exclusive
                              onChange={handleChange}
                              aria-label="Platform"
                          >
                              <ToggleButton value="My Courses">{translation?.gcMyCourses}</ToggleButton>
                              <ToggleButton value="Purchase Courses">
                                  {translation?.gcAllCourses}
                              </ToggleButton>
                          </ToggleButtonGroup>
              </Box> 
          </Box>

          {isApiCalling ? (
            <Box
              className="outerCard oCenter"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress />
            </Box>
          ) : filteredClasses?.length === 0 ? (
            <Box className="outerCard oCenter">
              <Typography
                variant="h3"
                sx={{
                  color: "#005DB8",
                  fontSize: "24px",
                  textTransform: "capitalize",
                  fontFamily: '"Rajdhani", sans-serif;',
                }}
              >
                {translation?.coming}
                <Typography variant="span" sx={{ color: "#EA4435" }}>
                  {" "}
                  {translation?.soon}
                </Typography>
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "18px",
                  color: "#727272",
                  textTransform: "uppercase",
                  mt: "10px",
                  fontFamily: '"Rajdhani", sans-serif;',
                }}
              >
                {translation?.NoClassesFound}
              </Typography>
            </Box>
          ) : (
            <Box className="outerCard oCenter">
              <Box sx={{ flexGrow: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  { alignment === 'My Courses' ? (
                    filteredClasses?.map((course, index) => (
                      <Grid item xs={12} key={index} sx={{ width: "100%" }}>
                        <Box className="card styledCard">
                          <Box className="courseBox">
                            <img
                              src={course.CourseImage}
                              alt="Course Thumbnail"
                              className="thumbnailImage"
                            />
                              {/* Right-aligned icons inside the image */}
                              <Box className="infoColumn infoColumnRight">
                                <Box className="infoItem">
                                  <AccessTimeIcon className="iconStyle" />
                                  <Typography variant="body2">{totalHoursInHours} {translation?.gcHours}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <OndemandVideoIcon className="iconStyle" />
                                  <Typography variant="body2">{totalSessionCount} {translation?.gcSessions}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <PeopleOutlineIcon className="iconStyle" />
                                  <Typography variant="body2">{course.RegisteredStudentCount} {translation?.gcStudents}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <StarPurple500Icon className="iconStyle" />
                                  <Typography variant="body2">{course.CourseRate} ({course.CourseRateCount}) </Typography>
                                </Box>
                              </Box>
                          </Box>
                          <Box className="contentBox">
                          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <button className="timerBtn">{course.CourseType}</button>
                                {course?.LimitedSeat == "true" && 
                                  <button className="timerBtn" style={{ color: 'red' }}>
                                    {translation?.gcLimitedSeats}
                                  </button>
                                }
                              </div>
                              {course.CourseStatusID === "1" && 
                                <button className="timerBtn">{translation?.gcSchedule}</button>
                              }
                          </Box>

                            <Typography className="courseTitle" variant="h6">
                              {course.CourseName}
                            </Typography>
                            <Typography className="courseDate" variant="body2">
                            {translation?.gcStartingFrom}:{" "}
                              {new Date(
                                course.CourseStartDate
                              ).toLocaleDateString()}
                            </Typography>

                            <Box sx={{ width: "100%" }}>
                              <Box display="flex" alignItems="center">
                                <Box width="100%" mr={1}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={(course.CompletedSessionCount / totalSessionCount)* 100}
                                  />
                                </Box>
                                <Box minWidth={35}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >{`${((course.CompletedSessionCount / totalSessionCount)* 100).toFixed(2)}%`}</Typography>
                                </Box>
                              </Box>
                                <Typography className="courseDate" variant="body2">
                                {course.CompletedSessionCount} {translation?.gcSessionCompleted}
                                </Typography>
                              <Box sx={{ mt: 2 }}>
                                {course?.Subject?.map((course) => (
                                  <button
                                    className="timerBtn"
                                    style={{ marginRight: "3px" }}
                                  >
                                    {course?.TopicName}
                                  </button>
                                ))}
                              </Box>
                            </Box>
                            <Box
                              className="priceBox"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "16px",
                              }}
                            >
                              {/* <Typography
                                  className="coursePrice"
                                  variant="body2"
                                  sx={{ fontWeight: 'medium', fontSize: '18px' }}
                              >
                                  {course.Subject?.[0]?.DiscountPrice} {course.Subject?.[0]?.Currency}
                              </Typography> */}

                              <Stack direction="row" spacing={2}>
                                <Avatar alt="Remy Sharp" src={course?.CounsellorList[0]?.CounsellorImage} />
                                <Box sx={{ padding: '5px'}}>
                                <Typography variant="h6">{course?.CounsellorList[0]?.CounsellorName}</Typography>
                                </Box>
                              </Stack>
                              <Box>
                                <Button
                                  startIcon={<WhatsAppIcon />}
                                  style={{
                                    backgroundColor: "#25D366",
                                    color: "white",
                                    textTransform: "none",
                                    padding: "6px 10px",
                                    borderRadius: "5px",
                                    marginRight: "3px",
                                    marginLeft: "3px"
                                  }}
                                  onClick={() => {
                                    // Add your WhatsApp link or functionality here
                                    window.open(
                                      `https://wa.me/${course?.CounsellorList[0]?.CounsellorPhone}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  {translation?.gcWhatsapp}
                                </Button>
                                <button
                                  className="viewDetailsBtn"
                                  onClick={() => viewSchedules(course.ID)}
                                >
                                  {translation?.gcViewSchedules}
                                </button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )) )
                    :
                    (
                    filteredClasses?.map((course, index) => {

                      const totalActualPrice = course?.Subject.reduce((sum, subject) => sum + parseFloat(subject.ActualPrice), 0);
                      const totalDiscountPrice = course?.Subject.reduce((sum, subject) => sum + parseFloat(subject.DiscountPrice), 0);
                      
                      return(
                      <Grid item xs={12} key={index} sx={{ width: "100%" }}>
                        <Box className="card styledCard">
                          <Box className="courseBox">
                            <img
                              src={course.CourseImage}
                              alt="Course Thumbnail"
                              className="thumbnailImage"
                            />
                              {/* Right-aligned icons inside the image */}
                              <Box className="infoColumn infoColumnRight">
                                <Box className="infoItem">
                                  <AccessTimeIcon className="iconStyle" />
                                  <Typography variant="body2">{totalHoursInHours} {translation?.gcHours}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <OndemandVideoIcon className="iconStyle" />
                                  <Typography variant="body2">{totalSessionCount} {translation?.gcSessions}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <PeopleOutlineIcon className="iconStyle" />
                                  <Typography variant="body2">{course.RegisteredStudentCount} {translation?.gcStudents}</Typography>
                                </Box>
                                <Box className="infoItem">
                                  <StarPurple500Icon className="iconStyle" />
                                  <Typography variant="body2">{course.CourseRate} ({course.CourseRateCount}) </Typography>
                                </Box>
                              </Box>
                            </Box>
                          <Box className="contentBox">
                          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <button className="timerBtn">{course.CourseType}</button>
                                {course?.LimitedSeat == "true" && 
                                  <button className="timerBtn" style={{ color: 'red' }}>
                                    {translation?.gcLimitedSeats}
                                  </button>
                                }
                              </div>
                              {course.CourseStatusID === "1" && 
                                <button className="timerBtn">{translation?.gcSchedule}</button>
                              }
                          </Box>

                            <Typography className="courseTitle" variant="h6">
                              {course.CourseName}
                            </Typography>
                            <Typography className="courseDate" variant="body2">
                              Start On:{" "}
                              {new Date(
                                course.CourseStartDate
                              ).toLocaleDateString()}
                            </Typography>

                            <Box
                              className="priceBox"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "16px",
                              }}
                            >
                              {/* <Typography
                                  className="coursePrice"
                                  variant="body2"
                                  sx={{ fontWeight: 'medium', fontSize: '18px' }}
                              >
                                  {course.Subject?.[0]?.DiscountPrice} {course.Subject?.[0]?.Currency}
                              </Typography> */}

                              <Box>
                              <Typography variant="body2" sx={{ textDecoration: "line-through", color: "gray" }}>
                                {totalActualPrice} {translation?.KuwaitiDinar}
                              </Typography>

                              {/* Discounted Price */}
                              <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
                                {totalDiscountPrice} {translation?.KuwaitiDinar}
                              </Typography>
                              </Box>
                              <Box>
                                <button
                                  className="viewDetailsBtn"
                                  onClick={() => viewDetails(course.ID)}
                                >
                                  {translation?.gcViewDetails}
                                </button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      )
                    }))
                  }
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <FooterSection />
    </div>
  );
};

export default Courses;
