import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";


const Schedule = ({ isOpen, handleClose , data }) => {
  
  const [monthIndex, setMonthIndex] = useState(0);
  const currentMonth = data[monthIndex];
  
  const handlePrev = () => {
    if (monthIndex > 0) setMonthIndex(monthIndex - 1);
  };
  
  const handleNext = () => {
    if (monthIndex < data.length - 1) setMonthIndex(monthIndex + 1);
  };
  
  if (!data || data.length === 0) {
    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 300 }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        {/* Header */}
        <Typography variant="h6" align="center" fontWeight="bold">
          Schedule
        </Typography>

        {/* Month Selector */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <IconButton onClick={handlePrev} disabled={monthIndex === 0}>
            <ArrowBack />
          </IconButton>
          <Typography variant="body1" fontWeight="bold">
            {currentMonth?.Name} 2025
          </Typography>
          <IconButton
            onClick={handleNext}
            disabled={monthIndex === data.length - 1}
          >
            <ArrowForward />
          </IconButton>
        </Grid>

        {/* Schedule List */}
        <Box sx={{ position: "relative", mt: 2, px: 3 }}>
          <Box
            sx={{
              position: "absolute",
              left: 85, // Adjust for alignment
              top: 0,
              bottom: 0,
              width: "2px",
              bgcolor: "#bbb",
            }}
          />

          {currentMonth?.Dates?.map((day, index) => {
            const [ month, year,  date,] = day.SessionDate.split("-");
            const subjectCount = day.Topic.length; // Get number of subjects
            const height = subjectCount * 52; // Approximate height of subject list dynamically

            return (
              <Box key={index} sx={{ display: "flex", mb: 3 }}>
                {/* Date and Dot Container */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 50,
                    height: height, // Set height dynamically
                  }}
                >
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        minWidth: 50,
                        position: "relative", // Ensure correct positioning
                      }}
                    >
                      {/* Date */}
                      <Typography variant="h6" fontWeight="bold">
                        {date}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(day.SessionDate).toLocaleDateString("en-US", {
                          weekday: "short",
                        })}
                      </Typography>

                      {/* Dot (Centered) */}
                      <Box
                        sx={{
                          position: "absolute",
                          left: "62px",
                          transform: "translateX(-50%)",
                          top: "50%", // Center it
                          transform: "translate(-50%, -50%)", // Ensure it aligns properly
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          bgcolor: "#888",
                        }}
                      />
                    </Box>
              </Box>

                {/* Subject List */}
                <Box sx={{ flexGrow: 1, pl: 3 }}>
                  {day.Topic.map((subject, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: subject.Color,
                        borderRadius: 2,
                        p: 1.5,
                        mb: 1,
                        color: "#fff",
                        minWidth: 150,
                        boxShadow: "2px 2px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <Typography variant="body1">{subject.Name}</Typography>
                      <Typography variant="body2" fontWeight="bold">
                        {subject.StartTime}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default Schedule;
